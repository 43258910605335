
.custom-content {
    margin-top: 18px;
    height: calc(100% - 70px);
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;

    .create-btn {
        width: 118px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #2DC079;
        border-radius: 4px;
        color: #2DC079;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
    }

    .custom-wrap {
        margin: 0 20px;
        flex: 1;
        height: 1%;
        display: flex;
        flex-direction: column;

        .customTable {
            margin-top: 12px;

            &.noData {
                ::v-deep .el-table__body-wrapper {
                    width: 100%;
                    height: 100% !important;

                    .el-table__empty-block {
                        border: unset;
                        display: flex;
                        flex-direction: column;

                        .el-table__empty-text {
                            flex: 1;
                            height: 1%;
                        }
                    }
                }
            }

            .no-data {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .no-data-content {
                    display: flex;
                    flex-direction: column;

                    .no-data-title {
                        color: #999999;
                        margin-top: 30px;
                    }

                    .btn-box {
                        display: flex;
                        justify-content: center;
                    }

                    .create-btn {
                        margin-top: 40px;
                    }
                }
            }
        }

        .item-content {
            display: flex;
            align-items: center;

            .item-left {
                width: 192px;
                height: 108px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .item-right {
                width: 192px;
                height: 108px;
                margin-left: 20px;

                .item-title {
                    color: #333333;
                }

                .right-content {
                    margin-top: 14px;

                    .content-item {
                        display: flex;
                        font-size: 14px;
                        color: #666666;

                        .content-right {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

        .pages-center {
            margin-bottom: 0;
        }
    }
}
